
import { defineComponent } from "vue";
import Button from "primevue/button";
import Card from "primevue/card";
import { mapActions, mapGetters } from "vuex";
import SoQuoteDialog from "@/components/Sales/SoQuoteDialog.vue";
import SalesQuotesDataTableWrapper from "@/components/Sales/SalesQuotesDataWrapper.vue";
import Footer from "@/components/Footer.vue";
export default defineComponent({
  components: {
    Button,
    Card,
    SoQuoteDialog,
    SalesQuotesDataTableWrapper,
    Footer,
  },
  computed: {
    ...mapGetters({
      getCustomer: "customerInquiry/getCustomer",
    }),
    getCustomerFields(): any {
      const { cust_id, terms, resale, code, name, contact_id_items } =
        this.getCustomer(this.index);
      return {
        cust_id,
        terms,
        resale,
        code,
        name,
        contact_id_items,
      };
    },
  },
  props: {
    index: Number,
    currentView: String,
  },
  data() {
    return {
      selectedSoId: null,
      selectedCustomer: null,
      isLoadingSoQuotes: false,
      showSoQuoteDialog: false,
      readOnly: true,
      soQuotes: [],
      newQuote: false,
      first: 0,
      rows: 10,
      sortOrder: 1,
      sortField: "",
      page: 1,
      order: false,
      hideLoadExistingQuoteBtn: false,
      orderRecordsBy: "",
      soQuote: null as unknown,
    };
  },
  methods: {
    ...mapActions({
      pushSaleQuotesId: "customerInquiry/pushSaleQuotesId",
    }),
    printExportData() {
      (this.$refs as any).salesQuotesTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesQuotesTable.clearAllFiltersAndFetch();
    },
    openSoQuote(edit: boolean) {
      this.showSoQuoteDialog = true;
      this.readOnly = edit;
      this.newQuote = !edit;
    },
    fetchSaleQuotes(quote: any) {
      if (this.showSoQuoteDialog == true) {
        this.showSoQuoteDialog = false;
      }
      this.isLoadingSoQuotes = true;
      this.pushSaleQuotesId({
        index: this.index,
        id: quote.id,
      });
      (this.$refs.salesQuotesTable as any).fetchQuotesData(false);
    },
  },
});
